import { graphql } from "gatsby"
import React from "react"
import { navigate } from "@reach/router"
import { FiChevronLeft, FiShare } from "react-icons/fi"

// import Layout from "../components/layout"
// import SEO from "../components/seo"
import Layout from "acciondigital-basic-theme/src/components/layout"
import SEO from "acciondigital-basic-theme/src/components/seo"
import PublicacionDetalleBasico from "acciondigital-basic-theme/src/components/PublicacionDetalleBasico"

export const query = graphql`
  query($key_producto: String!) {
    contentfulProductos(slug: { eq: $key_producto }) {
      titulo
      slug
      fechaPublicacion(formatString: "MMMM Do, YYYY")
      etiquetas
      imagenPrincipal {
        fixed(width: 190, height: 102) {
          ...GatsbyContentfulFixed
        }
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      descripcionCorta {
        descripcionCorta
        childMarkdownRemark {
          html
        }
      }
      descripcionLarga {
        childMarkdownRemark {
          html
        }
      }
      masImagenes {
        id
        fixed(width: 190, height: 102) {
          ...GatsbyContentfulFixed
        }
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

// (maxHeight: 196, resizingBehavior: SCALE) maxWidth: 350,

/* 
file(relativePath: { eq: "img_prod.jpg" }) {
  childImageSharp {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
 */

const divStyle = {
  "white-space": "pre-line",
}

const PublicacionRevista = ({ data }) => {
  const producto = data.contentfulProductos
  const siteMeta = data.site.siteMetadata
  const textoBoton = "Me Interesa"

  const linkBoton =
    "https://wa.me/529711104164?text=" +
    encodeURIComponent(
      "Buen día, me interesa - " +
        producto.titulo +
        "\n" +
        siteMeta.siteUrl +
        "/producto/" +
        producto.slug +
        "/"
    )

  return (
    <Layout >
      <SEO
        title={producto.titulo}
        imageSource={producto.imagenPrincipal.fluid.src}
        imageAlt={producto.titulo}
        description={
          producto.descripcionCorta.descripcionCorta.substring(0, 150) +
          (producto.descripcionCorta.descripcionCorta.length > 150
            ? " . . ."
            : "")
        }
      />
      <h1>{producto.titulo}</h1>
      <p>
        <a href="#" onClick={() => navigate(-1)}>
          <FiChevronLeft />
          Regresar
        </a>
        {/* <a
          sx={{
            variant: "buttons.transparent",
            fontSize: "15px",
            color: "brand_02.0",
            paddingTop: "30px",
          }}
          onClick={() => typeof window !== "undefined" && window.history.back()}
          aria-label="Volver"
        >
          <FiChevronLeft /> volver
        </a> */}
      </p>
      <PublicacionDetalleBasico
        nombre={producto.titulo}
        descripcion={producto.descripcionCorta.descripcionCorta}
        precio={0}
        imageSharpFluid={producto.imagenPrincipal.fluid}
        imageSharp={producto.imagenPrincipal}
        linkBoton={linkBoton}
        textoBoton={textoBoton}
        masImagenes={producto.masImagenes}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: producto.descripcionLarga.childMarkdownRemark.html,
          }}
        />
      </PublicacionDetalleBasico>
    </Layout>
  )
}

export default PublicacionRevista
